WebFont.load({
  typekit: {id: 'zhu6dml'},
  google: {
    families: ['Cormorant+Infant', 'Crimson+Text:400;400i;600;600i;700;700i&display=swap']
  },
  custom: {
    families: ['FontAwesome'],
    urls: [
      'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
    ]
  }
});

(function ($) {
  var is_index = $('html').hasClass('homepage');

  function refresh_size_queries() {
    var classes = [];
    var scrollbarwidth = getScrollbarWidth();
    window_width = $(window).width() + scrollbarwidth;
    window_height = $(window).height();
    is_phone = (window_width < 768);
    is_mobile = (window_width < 992);
    is_tablet_portrait = (window_width >= 768 && window_width < 992);
    is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
    is_tablet = is_tablet_portrait || is_tablet_landscape;
    is_desktop = (window_width >= 992);
    is_desktop_large = (window_width >= 1200);
    is_desktop_larger = (window_width >= 1400);

    if (is_phone) {
      classes.push('mq_phone');
    }
    if (is_mobile) {
      classes.push('mq_mobile');
    }
    if (is_tablet_portrait) {
      classes.push('mq_tablet_portrait');
    }
    if (is_tablet_landscape) {
      classes.push('mq_tablet_landscape');
    }
    if (is_tablet) {
      classes.push('mq_tablet');
    }
    if (is_desktop) {
      classes.push('mq_desktop');
    }
    if (is_desktop_large) {
      classes.push('mq_desktop_large');
    }
    if (is_desktop_larger) {
      classes.push('mq_desktop_larger');
    }


    $('html').removeClass('mq_phone');
    $('html').removeClass('mq_mobile');
    $('html').removeClass('mq_tablet_portrait');
    $('html').removeClass('mq_tablet_landscape');
    $('html').removeClass('mq_tablet');
    $('html').removeClass('mq_desktop');
    $('html').removeClass('mq_desktop_large');
    $('html').removeClass('mq_desktop_larger');

    $('html').addClass(classes.join(' '));

  }

  function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";
    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
  }

  function casagredo_header_position() {
    var header = $('#header_wrapper'),
        custom = 'custom',
        fixed = 'fixed',
        no_slide = 'no_slide',
        slideshow = $('#map_wrapper').length > 0 ? $('#map_wrapper') : $('.slideshow-container'),
        breakpoint = header.height(),
        scroll = $(window).scrollTop();

    if (slideshow.length > 0) {
      if (scroll > breakpoint) {
        header.addClass(fixed);
        header.removeClass(custom);
      } else {
        header.removeClass(fixed);
        header.addClass(custom);
      }
    } else {
      header.addClass(no_slide);
    }
  }

  function casagredo_open_menu() {
    var box = $('#big_menu_wrap'),
        open = $('#open_menu'),
        close = box.find('.close_menu');

    open.click(function () {
      $('html').addClass('no-scroll');
      lazyLoadInstance.update();
      box.fadeIn();
    });
    close.click(function () {
      $('html').removeClass('no-scroll');
      box.fadeOut();
    });
  }

  function casagredo_open_menu_mobile() {
    var btn = $('a#open_menu_mobile');
    var sidebar = $('#mobile_menu_wrapper');
    if (btn.length == 0 || sidebar.length == 0) return;
    var sidebar_overlay = $('#mobile_menu_overlay');
    var mClass = ('visible');
    var htmlClass = ('no-scroll');
    var html = $('html');
    var mobile_menu = $("#mobile_menu");
    var pageWithSubMenu = mobile_menu.find(".menu-item-has-children");
    var subMenuLink = pageWithSubMenu.find("> a");

    // open
    btn.on('click tap', function () {
      if (!sidebar.hasClass(mClass)) {
        sidebar.addClass(mClass);
        sidebar_overlay.addClass(mClass);
        html.addClass(htmlClass);
        if ($(window).width() < 350) {
          sidebar.width($(window).width()).css('left', 'auto');
        }
      }
    });
    // close
    sidebar_overlay.on('click tap', function () {
      if (sidebar.hasClass(mClass)) {
        sidebar.removeClass(mClass);
        sidebar_overlay.removeClass(mClass);
        html.removeClass(htmlClass);
        if ($(window).width() < 350) {
          sidebar.css('left', -$(window).width());
        }
      }
    });
    // close
    $('.close_sidebar').on('click tap', function () {
      if (sidebar.hasClass(mClass)) {
        sidebar.removeClass(mClass);
        sidebar_overlay.removeClass(mClass);
        html.removeClass(htmlClass);
        if ($(window).width() < 350) {
          sidebar.css('right', -$(window).width());
        }
      }
    });

    // menu sub
    subMenuLink.on("click", function (e) {
      e.preventDefault();
      var parent_li = $(this).parent("li");
      if (!parent_li.hasClass(mClass)) {
        $("#menu_mobile").find(".sub-menu").fadeOut().parent("li").removeClass(mClass);
        parent_li.addClass(mClass).find(".sub-menu").fadeIn();
      } else {
        parent_li.removeClass(mClass).find(".sub-menu").fadeOut();
      }
    });

  }

  function casagredo_social_login() {
    $('#login a').on('mouseover', function () {
      $('.dropdown').fadeIn(1000);
    });
    // $('.dropdown .close-btn').on('mou', function(){
    $('body').on('click', function () {
      $('.dropdown').fadeOut('fast');
    });

    //Close and open guest accoun widget
    // if(!is_mobile){
    $('.close-offer-widget').on('click', function () {
      if (!$('.offer-login-wrapper').hasClass('opened')) {
        $('.offer-login-wrapper').addClass('opened');
      } else {
        $('.offer-login-wrapper').removeClass('opened');
      }
    });
    // }
  }

  function casagredo_languages() {
    $('.current_language').on('click', function () {
      $('.other_languages').fadeToggle();
    });

    $(document).mouseup(function (e) {
      if (!$('.current_language').is(e.target) && $('.current_language').has(e.target).length === 0) {
        $('.other_languages').removeClass('show');
        $('.other_languages').fadeOut();
      }
    });
  }

  function menuWithBgImages() {
    var wrap = $('#big_menu_wrap');
    var wrapImg = $('#menu_bg_image .image');
    var mainMenu = $('#main_menu .menu');
    var menuLis = wrap.find('#main_menu .menu > li.test');
    var menuCurrentLiParent = wrap.find('#main_menu .menu > li.current_page_parent');
    menuLis.each(function () {
      var single_li = $(this);
      single_li.hover(function () {
        $('.sub-menu').css({'display': 'none'});
        wrapImg.attr('src', single_li.data('cover')).finish().animate({
          opacity: "0.5"
        }, 600);
        wrapImg.attr('src', single_li.data('cover')).finish().animate({
          opacity: "1"
        }, 600);

        // menuLis.find('> .sub-menu').css({'display': 'none'});
        single_li.find('> .sub-menu').css({'display': 'flex'});
      });
    });
    mainMenu.mouseleave(function () {
      // menuLis.find('> .sub-menu').css({'display': 'none'});
      // $('.sub-menu').css({'display': 'none'});
      if (menuCurrentLiParent.data('cover') == undefined) {
        wrapImg.attr('src', front_page_img);
      } else {
        wrapImg.attr('src', menuCurrentLiParent.data('cover'));
      }
    });
  }

  function casagredo_slideshow() {
    $('.slideshow').not('.slick-initialized').slick({
      rows: 0,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 5000,
      fade: true,
      dots: true,
      pauseOnHover: false,
      cssEase: 'ease-in-out'
    });
  }

  function casagredo_responsive_slideshow(){
    var slide = $('.slideshow .single-image');
    slide.each( function (){
      var desktop_img  = $(this).attr('data-desktop'),
          ipad_img  =  $(this).attr('data-ipad'),
          phone_img = $(this).attr('data-phone');
      if ($(window).width() <= 414){
        $(this).attr('src', phone_img);
      } else if ($(window).width() > 414 && $(window).width() < 991){
        $(this).attr('src', ipad_img);
      } else {
        $(this).attr('src', desktop_img);
      }
    });
  }

  function casagredo_minigallery() {
    var minigallery = $('.minigallery-carousel');
    if (!$('html').hasClass('page-template-template-weddings')) {
      if (minigallery.length > 0) {
        minigallery.on('init', function (event, slick) {
          lazyLoadInstance.update();
        });
        minigallery.not('.slick-initialized').slick({
          slidesToShow: 2,
          arrows: false,
          dots: true,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 1180,
              settings: {
                centerMode: true
              }
            },
          ]
        });
      }
    }
  }

  function casagredo_weddings_minigallery() {
    var minigallery = $('.minigallery-carousel');
    if ($('html').hasClass('page-template-template-weddings')) {
      if (minigallery.length > 0) {
        minigallery.on('init', function (event, slick) {
          lazyLoadInstance.update();
        });
        minigallery.not('.slick-initialized').slick({
          slidesToShow: 2,
          arrows: false,
          dots: true,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 1180,
              settings: {
                centerMode: true
              }
            },
            {
              breakpoint: 992,
              settings: {
                dots: false,
                arrows: true,
                prevArrow: '<a class="arrow inverse prev slick-arrow" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="arrow inverse next slick-arrow" href="javascript:;"><span></span></a>',
              }
            }
          ]
        });
      }
    }
  }

  function page_preview_minigallery() {
    var minigallery = $('.single_page_preview .page_preview_carousel');
    if (minigallery.length > 0) {
      minigallery.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      minigallery.not('.slick-initialized').slick({
        slidesToShow: 2,
        rows: 0,
        arrows: false,
        variableWidth: true
      });
    }
  }

  function suite_children_minigallery() {
    var minigallery = $('.suite-gallery .suite_minigallery_carousel');
    if (minigallery.length > 0) {
      minigallery.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      minigallery.not('.slick-initialized').slick({
        slidesToShow: 2,
        rows: 0,
        variableWidth: true,
        arrows: true,
        prevArrow: '<a class="arrow inverse prev slick-arrow" href="javascript:;"><span></span></a>',
        nextArrow: '<a class="arrow inverse next slick-arrow" href="javascript:;"><span></span></a>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              centerMode: true
            }
          },
        ]
      });
    }
  }

  function children_minigallery() {
    var minigallery = $('#single_page_preview.preview_children_minigallery .page_preview_carousel');
    if (minigallery.length > 0) {
      minigallery.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      minigallery.not('.slick-initialized').slick({
        slidesToShow: 2,
        rows: 0,
        variableWidth: true,
        arrows: true,
        prevArrow: '<a class="arrow inverse prev slick-arrow" href="javascript:;"><span></span></a>',
        nextArrow: '<a class="arrow inverse next slick-arrow" href="javascript:;"><span></span></a>',
      });
    }
  }

  function children_collapsible_minigallery() {
    var minigallery = $('#single_page_preview.collapsible_children .page_preview_carousel');
    if (minigallery.length > 0) {
      minigallery.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      minigallery.not('.slick-initialized').slick({
        arrows: true,
        variableWidth: true,
        prevArrow: '<a class="arrow inverse prev slick-arrow" href="javascript:;"><span></span></a>',
        nextArrow: '<a class="arrow inverse next slick-arrow" href="javascript:;"><span></span></a>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              centerMode: true
            }
          },
        ]
      });
    }
  }

  function casagredo_siblings() {
    var siblings = $('.preview-pages-wrap');
    if (siblings.length > 0) {
      siblings.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      siblings.not('.slick-initialized').slick({
        slidesToShow: 2,
        rows: 0,
        arrows: false,
        dots: true,
        responsive: [
          {
            breakpoint: 1180,
            settings: {
              centerMode: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '15px'
            }
          }
        ]
      });
    }
  }

  function casagredo_dynamic_calendar() {
    var btns = $('#open_dynamic_calendar, #main_book_mobile');
    var calendar_wrapper = $('#dyncal_wrapper');
    btns.click(function () {
      if (!calendar_wrapper.hasClass('visible')) {
        calendar_wrapper.addClass('visible');
        $('html').addClass('no-scroll');
      }
    });

    $('.close_calendar').click(function () {
      if (calendar_wrapper.hasClass('visible')) {
        calendar_wrapper.removeClass('visible');
        $('html').removeClass('no-scroll');
      }
    });
  }

  function videoHomepage() {
    if ($('html').hasClass('tablet')) {
      var quality_video = 'auto';
    } else {
      var quality_video = 'default';
    }

    //Player setting
    window.player = new Plyr('#player', {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'pip',
        'airplay',
        'fullscreen'
      ],
      quality: {
        default: quality_video,
      },
      storage: {
        enabled: true,
        key: 'ply'
      }
    });
    if ($('.video-play').hasClass('loaded')) {
      $('.slideshow').slick('slickPause');
      $('.slideshow-container').addClass('playing_video');
      if (is_mobile) {
        var device_custom_height = window.innerHeight - 60;
        if (window.matchMedia("(orientation: landscape)").matches) {
          $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', device_custom_height)
        } else {
          $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', '400px')
        }
      }
      $('#video_box').fadeIn(1000);
      player.on('ready', function () {
        player.play();
      });
    }

    //On click you hide the video
    $('.close_video').on('click', function () {
      $('.slideshow-container').removeClass('playing_video');
      if (is_mobile) {
        if (window.matchMedia("(orientation: landscape)").matches) {
          $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', '100vh')
        } else {
          $('.slideshow-container.fullscreen').css('height', '640px');
          $('#video_box .video_is_mobile.fullscreen').css('height', '400px')
        }
      }
      player.stop();
      player.destroy();
      $('#video_box').fadeOut(1000);
      $('.slideshow').slick('slickPlay');
    });

    //At the end of the video hide video
    player.on('ended', function (event) {
      $('.slideshow-container').removeClass('playing_video');
      $('#video_box').fadeOut(1000);
      player.stop();
      player.destroy();
      $('.slideshow').slick('slickPlay');
    });
  }

  function videoHeight() {
    var WH = $(window).height();
    if ($('body').hasClass('home')) {
      $('#video_box').css({
        'height': WH
      });
    }
  }

  function casagredo_awards() {
    $('#awards_section').slick({
      slidesToShow: 3,
      centerPadding: '30px',
      arrows: true,
      prevArrow: '<a class="arrow prev slick-arrow" href="javascript:;"><span></span></a>',
      nextArrow: '<a class="arrow next slick-arrow" href="javascript:;"><span></span></a>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        },
      ],
    });
  }

  function casagredo_collapsibleContent() {
    var button = $('.the-content-section .discover-more, .discover-more.collapsible');
    if (button.length > 0) {
      button.on('click', function () {
        if (!$(this).hasClass('open')) {
          $(this).addClass('open');
          $(this).parent().parent().find('.the-extra-content-wrapper').css({
            height: $(this).parent().parent().find('.the-extra-content-wrapper .the-extra-content').outerHeight(true)
          });
          $(this).text($(this).data('closetext'));
        } else {
          $(this).removeClass('open');
          $(this).parent().parent().find('.the-extra-content-wrapper').css({
            height: 0
          });
          $(this).text($(this).data('opentext'));
        }
      });
    }
  }

  function opencloseviewmore() {
    var button = $('.view_more_content');
    button.click(function (e) {
      e.stopPropagation();
      // this
      var anchor = $(this);
      var more_text_box = $(this).parent().find('.more_text_box');
      var transport_expander = $(this).parent().find('.transport_expander');
      // reset
      var more_text_box_opened = $('.more_text_box.opened');
      if (more_text_box_opened.length > 0) {
        more_text_box_opened.stop().animate({height: 0}, 300, function () {
          more_text_box_opened.parent().find('.view_more_content').removeClass('open');
          more_text_box_opened.removeClass('opened');
          transport_expander.text('+');
        });
      }
      if ($(this).hasClass('open')) {
        more_text_box.stop().animate({height: 0}, 300, function () {
          anchor.removeClass('open');
          more_text_box.removeClass('opened');
          transport_expander.text('+');
        });
      } else {
        var customHeight = $(this).parent().find('.more_text_box .more_text').outerHeight(true);
        more_text_box.stop().animate({height: customHeight}, 300, function () {
          anchor.addClass('open');
          more_text_box.addClass('opened');
          $('.transport_expander').text('+');
          transport_expander.text('-');
        });
      }
    })
  };

  function offersHeight() {
    var box = $('#offers_list .offer .title_wrap, #offers_section .single_offer .title_wrap');
    // if (box.size() > 0) {
    var max_height = 0;

    box.each(function (k, item) {
      var height = $(item).outerHeight(true);
      if (height > max_height) {
        max_height = height;
      }

    });
    box.each(function (k, item) {
      $(item).css({
        'height': max_height
      });
      $(item).addClass('vAlign');
    });
    // }
  }

  function OffersCarousel() {
    var wrap_offers = $('#preview_offers .preview-pages-wrap');
    // if (wrap_offers.size() > 0) {
    wrap_offers.on('init', function (event, slick) {
      lazyLoadInstance.update();
    });
    wrap_offers.not('.slick-initialized').slick({
      slidesToShow: 2,
      arrows: false,
      dots: true,
      // variableWidth: true,
      rows: 0,
      slide: '.offer_wrap',
      // infinite: true,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            centerMode: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '15px'
          }
        }
      ]
    });
    // }
  }

  function web_sdk_offers() {
    $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
    $('div[data-websdk]').each(function () {
      var container = $(this),
          model_name = container.data('websdk'),
          id = container.attr('id'),
          custom_template = container.data('websdk-template'),
          template_id, config, model, template, html;
      template_id = custom_template ? custom_template : '#' + id + '_template';
      template = $(template_id).text();
      if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined' /*&& typeof $fbsdk !== "undefined"*/) {
        config = websdk_config[id];
        $fbsdk.baseHost = config.baseHost;
        if (model_name == "Accommodations") {
          web_sdk_accomodations(container, template, config);
          return;
        }
        model = new $fbsdk[model_name](config.params);
        model.setAuthToken(config._authCode);
        model.load(function (error) {
          var data, i;
          if (!error) {
            //YEAH, this happens when we have data and we're ready to use it!
            data = this.getRawData();
            if (model_name == 'Offers' && data.rates.length == 0) {
              container.addClass('no_offers');
              container.closest('.special_offers_row').find('.hotel .price').remove();
            }
            container.closest('.loading').addClass(' processed noloading');

            if (typeof data.rates !== 'undefined') {

              if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers !== '') {
                var temp_rates = [];
                for (var i = 0; i < data.rates.length; i++) {
                  if (typeof data.rates[i] !== 'undefined') {
                    if ($.inArray(data.rates[i].rate.name, config.excluded_offers) == -1) {
                      // if is allowed
                      temp_rates.push(data.rates[i]);
                    }
                  }
                }
                // OVERRRIDE THE OBJ
                data.rates = temp_rates;
              }

              if (typeof config['putBestPriceIn'] !== 'undefined') {
                var bestprice = false;
                for (var i = 0; i < data.rates.length; i++) {
                  if (bestprice === false || data.rates[i].quotation.totalPrice < bestprice) {
                    bestprice = data.rates[i].quotation.totalPrice;
                  }
                }
                if (bestprice) {
                  container.closest(config['putBestPriceIn'].closest).find(config['putBestPriceIn'].find).html(bestprice);
                }
              }
              if (typeof config.number !== 'undefined' && config.number <= data.rates.length) {
                data.rates.length = parseInt(config.number, 10);
              }
              for (var i = 0; i < data.rates.length; i++) {
                if (i % 2 == 0) {
                  data.rates[i].rate.classname = 'even';
                } else {
                  data.rates[i].rate.classname = 'odd';
                }
                data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 50) + '...';
              }
            }

            html = Mustache.render(template, data);
            container.html(html);

            // updateLazyLoad();
            lazyLoadInstance.update()

            //da qui in poi ho  HTML delle offerte nella pagina
            var maxHeight = 0;
            var offers = container.find('.promotion, .offer_wrap');
            offers.each(function (el, i) {
              var offer = $(this);
              var height = offer.height();
              if (height > maxHeight) {
                maxHeight = height;
              }
              var description = offer.find('.details');
              var content = $(this).find('.preview_content');

              offer.find('a.info').on('click', function () {
                $.fancybox({
                  content: description
                });
              });
              offer.find('.preview_content').css({'padding-top': ($('.promotion').height() - $('.preview_content').height()) / 2});
            });
            // offers.each(function () {
            //     $(this).css('height', maxHeight);
            // });
            // offersHeight();
            if (is_index) {
              OffersCarousel();
            }


          } else {
            // in caso di errore
            container.fadeOut();
          }
        });
      }
    });
  }

  function casagredo_initializeMap(div) {
    if (typeof (div) === "undefined") div = "map_canvas";
    if (typeof (directionsService) === "undefined") directionsService = new google.maps.DirectionsService();
    if (typeof (bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();
    directionsDisplay = new google.maps.DirectionsRenderer();

    var marker, i;

    var infoWindow = new google.maps.InfoWindow({
      'maxWidth': 500,
      'maxHeight': 400,
    });


    center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

    map = new google.maps.Map(document.getElementById(div), {
      // scroll wheel
      scrollwheel: false,
      //zoom
      zoom: map_config.map_zoom,
      zoomControl: map_config.map_zoom_control,
      zoomControlOptions: {
        position: google.maps.ControlPosition[map_config.zoom_control_position]
      },
      //position
      center: new google.maps.LatLng(center[0], center[1]),
      //map type
      mapTypeId: google.maps.MapTypeId[map_config.map_type],
      mapTypeControl: map_config.map_type_control,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
        style: google.maps.MapTypeControlStyle.DEFAULT
      },
      // default pan control
      panControl: false,
      panControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      // scale control - image with the scale index (m,km,etc)
      scaleControl: false,
      scaleControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      // 	streetview controls
      streetViewControl: map_config.map_streetview_control,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      }

    });

    var styles = [{
      "featureType": "poi.business",
      "elementType": "labels",
      "stylers": [{"visibility": "off"}]
    }, {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
    }, {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
    }, {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
    }, {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
    }, {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
    }, {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{"color": "#dedede"}, {"lightness": 21}]
    }, {
      "elementType": "labels.text.stroke",
      "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
    }, {
      "elementType": "labels.text.fill",
      "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
    }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
    }, {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
    }, {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
    }];
    map.setOptions({styles: styles});

    for (i = 0; i < map_config.markers.length; i++) {
      if (i == 0) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(map_config.markers[0].latitude, map_config.markers[0].longitude),
          map: map,
          icon: map_config.markers[0].icon_hotel,
          zIndex: 99999999
        });


        google.maps.event.addListener(marker, 'click', (function (marker, i) {
          return function () {
            infoWindow.setContent(map_config.markers[i].html);
            infoWindow.open(map, marker);
          };
        })(marker, i));
        $(document).on('click', '.close', function (event) {
          infoWindow.close(map, marker);
        });
      } else {
        // if($('html').hasClass('location-page')){
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(map_config.markers[i].latitude, map_config.markers[i].longitude),
          map: map,
          icon: map_config.markers[i].icon_poi,
          // category: map_config.markers[i].category,
          zIndex: 999999999,
          visible: true
        });

        markers.push(marker);
        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
          return function () {
            infoWindow.setContent(map_config.markers[i].html);
            infoWindow.open(map, marker);
          };
        })(marker, i));

        // if (map_config.set_bounds) {
        //     map.fitBounds(bounds);
        // }
      }

      // }

    }
    for (i = 0; i < markers.length; i++) {
      marker = markers[i];
      marker.setVisible(true);
    }
    //Filtered markers
    $('.map-categories li a').on('click tap', function () {
      var anchor = $(this);
      var category = anchor.data('category');
      anchor.parent().addClass('active').siblings().removeClass('active');
      casagredo_filtered_markers(category);
    });
  }

  function casagredo_filtered_markers(category) {
    for (i = 0; i < markers.length; i++) {
      marker = markers[i];
      if (category == 'all') {
        marker.setVisible(true);
      } else if (marker.category == category) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
    }
  }

  function casagredo_calcRoute() {
    var start = document.getElementById('itineraryFrom').value;
    var end = '' + map_config.hotel_info.latitude + ',' + map_config.hotel_info.longitude;

    // remove previous message if present
    if ($('.itineraryPanelWrapper').length > 0) {
      $('.itineraryPanelWrapper').remove();
    }
    // delete previous results
    if (typeof directionsDisplay === 'undefined') {
      directionsDisplay = new google.maps.DirectionsRenderer();
    }
    //reset close button display
    $(".close_map.bottom").css("display", "inherit");

    $('#map_directions').fadeIn();

    directionsDisplay.setPanel(null);
    directionsDisplay.setMap(map);
    directionsDisplay.setPanel(document.getElementById('map_directions_content'));


    if (typeof start === 'undefined' || start == '') {
      start = $('input[name=itineraryFrom]').val();
    }

    if (start == '') {
      $('<div class="title">' + empty_message_html + '</div>').appendTo(jQuery('#map_directions_content'));
      directionsDisplay.setPanel(null);
      directionsDisplay.setMap(null);
      map.setZoom(map_config.map_zoom);
      $(".close_map.bottom").css("display", "none");

    } else {

      var request = {
        origin: start,
        destination: end,
        travelMode: google.maps.TravelMode['WALKING']
      };

      directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          $('<div class="title ok_messagge">' + ok_message_html + '</div>').appendTo($('#map_directions_content'));
        } else if (status == google.maps.DirectionsStatus.NOT_FOUND) {
          $('<div class="title">' + not_found_message_html + '</div>').appendTo($('#map_directions_content'));
          directionsDisplay.setPanel(null);
          directionsDisplay.setMap(null);
          map.setZoom(map_config.map_zoom);
          map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
          $(".close_map.bottom").css("display", "none");
        } else {
          $('<div class="title">' + err_message_html + '</div>').appendTo($('#map_directions_content'));
          directionsDisplay.setPanel(null);
          directionsDisplay.setMap(null);
          map.setZoom(map_config.map_zoom);
          map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
          $(".close_map.bottom").css("display", "none");
        }
      });
    }

    //this part handle the close_map button
    $('.close_map').click(function () {
      $('#map_directions').fadeOut(function () {
        directionsDisplay.setPanel(null);
        directionsDisplay.setMap(null);
        if (map_config.markers.length > 0) {
          // map.fitBounds(bounds);
          map.setZoom(map_config.map_zoom);
        } else {
          map.setZoom(map_config.map_zoom);
        }
        map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
      });
      $('.itineraryPanelWrapper').remove();
    });

  }

  function casagredo_social_media() {
    var social_media_carousel = $('.social-media-carousel');
    if (social_media_carousel.length > 0) {
      social_media_carousel.on('init', function (event, slick) {
        lazyLoadInstance.update();
      });
      social_media_carousel.not('.slick-initialized').slick({
        rows: 0,
        slidesToShow: 4,
        variableWidth: true,
        pauseOnHover: false,
        arrows: true,
        prevArrow: '<a class="arrow inverse prev slick-arrow" href="javascript:;"><span></span></a>',
        nextArrow: '<a class="arrow inverse next slick-arrow" href="javascript:;"><span></span></a>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              centerMode: true
            }
          },
        ]
      });
    }
  }

  function casagredo_filter_children() {
    var wrap = $('.preview-children-wrap'),
        tabs = $('.filter-item'),
        items = wrap.find('.single-page'),
        mobile_select = $('.mobile_filter_container select');

    function filtering(catToMatch) {
      AOS.init({
        disable: true,
      });
      var counter = 1;
      items.each(function () {
        var item = $(this);
        item.removeClass('even odd');
        if (catToMatch == '*') {
          item.addClass(counter % 6 === 0 ? 'sixth_item' : '');
          item.addClass(counter % 4 === 0 ? 'fourth_item' : '');
          item.addClass(counter % 2 === 0 ? 'odd' : 'even');
          items.removeClass('hidden');
          counter++;
        } else if (item.hasClass(catToMatch)) {
          item.addClass(counter % 6 === 0 ? 'sixth_item' : '');
          item.addClass(counter % 4 === 0 ? 'fourth_item' : '');
          item.addClass(counter % 2 === 0 ? 'odd' : 'even');
          item.removeClass('hidden');
          counter++;
        }
      });
    }

    if (wrap.length > 0) {
      tabs.click(function () {
        var tab = $(this);
        var catToMatch = tab.attr('data-category');
        tabs.removeClass('is-active');
        tab.addClass('is-active');
        items.addClass('hidden');
        filtering(catToMatch);
        tabs.removeClass('is-active');
        tab.addClass('is-active');
      });
      // mobile_select.change(function () {
      //     var catToMatch = this.value;
      //     items.addClass('hidden');
      //     filtering(catToMatch);
      //     tab.textContent('asd');
      // });
      mobile_select.change(function () {
        var catToMatch = this.value;
        items.addClass('hidden');
        filtering(catToMatch);
        $('.dropbtn').text($(this).text());
      });
    }
  }

  function casagredo_filter_photogallery() {
    var wrap = $('.photogallery-wrap'),
        tabs = $('.filter-item'),
        items = wrap.find('.single-image'),
        mobile_select = $('.mobile_filter_container select');

    function filtering(catToMatch) {
      AOS.init({
        disable: true,
      });
      var counter = 1;
      items.removeClass('masonry');
      items.each(function (index) {
        var item = $(this);
        item.removeClass('even odd second_item fourth_item sixth_item');
        if (catToMatch == '*') {
          items.removeClass('hidden');
          items.addClass('masonry');
          counter++;
        } else if (item.hasClass(catToMatch)) {
          item.addClass(counter % 2 === 0 ? 'even' : 'odd');
          item.addClass(counter % 6 === 2 ? 'second_item' : '');
          item.addClass(counter % 6 === 4 ? 'fourth_item' : '');
          item.addClass(counter % 6 === 0 ? 'sixth_item' : '');
          item.removeClass('hidden');
          counter++;
        }
      });
    }

    if (wrap.length > 0) {
      tabs.click(function () {
        var tab = $(this);
        var catToMatch = tab.attr('data-category');
        tabs.removeClass('is-active');
        tab.addClass('is-active');
        items.addClass('hidden');
        filtering(catToMatch);
        tabs.removeClass('is-active');
        tab.addClass('is-active');
      });
      // mobile_select.change(function () {
      //     var catToMatch = this.value;
      //     items.addClass('hidden');
      //     filtering(catToMatch);
      //     tab.textContent('asd');
      // });
      mobile_select.change(function () {
        var catToMatch = this.value;
        items.addClass('hidden');
        filtering(catToMatch);
        $('.dropbtn').text($(this).text());
      });
    }
  }

  function casagredo_filter_experiences() {
    var wrap = $('.experiences-children-wrap'),
        tabs = $('.filter-item'),
        items = wrap.find('.single-child'),
        mobile_select = $('.mobile_filter_container select');

    function filtering(catToMatch) {

      var counter = [];
      if (catToMatch == '*') {
        items.removeClass('hidden');
        items.removeClass('full_width_child');
        if (items.length % 2 != 0) {
          items.last().addClass('full_width_child');
        }
      }
      items.each(function () {
        var item = $(this);
        if (item.hasClass(catToMatch)) {
          counter.push(item);
        }
      });

      for (var i = 0; i < counter.length; i++) {
        items.removeClass('full_width_child');
        counter[i].removeClass('hidden');
        if (counter.length % 2 != 0) {
          counter[counter.length - 1].addClass('full_width_child');
        }
      }
    }

    if (wrap.length > 0) {
      tabs.click(function () {
        AOS.init({
          disable: true,
        });
        var tab = $(this);
        var catToMatch = tab.attr('data-category');
        tabs.removeClass('is-active');
        tab.addClass('is-active');
        items.addClass('hidden');
        filtering(catToMatch);
        tabs.removeClass('is-active');
        tab.addClass('is-active');
      });
      mobile_select.change(function () {
        var catToMatch = this.value;
        items.addClass('hidden');
        filtering(catToMatch);
      });
    }
  }

  function casagredo_form() {
    // if($(".gform_wrapper")){
    //     $( ".gform_footer" ).prepend($( "<div class='required-fields'><span>*</span>"+ required_fields+"</div>" ));
    // }
    console.log('nondovreiesistere');

    if ($('body').hasClass('gform_open_fancybox')) {
      $('.request-quote').trigger('click');
    }

    $('.datepicker').each(function () {
      $(this).datepicker({
        minDate: "d",
        defaultDate: "d",
        dateFormat: "d/m/yy",
        changeMonth: true,
        changeYear: false,
      });
    });
  }

  function newsletter_validation() {
    // privacy validation
    $('#mc_embed_signup #mc-embedded-subscribe').on('click tap', function (e) {
      e.preventDefault();
      var form = $('#mc_embed_signup form');
      var privacy = $('.newsletter_box .field.privacy');
      var privacy_checkbox = privacy.find('input[name=privacy]');
      if (privacy_checkbox.is(":checked")) {
        privacy.removeClass('error');
        privacy.find('.error').remove();
        form.submit();
      } else {
        //  privacy_error
        privacy.find('.error').remove();
        privacy.append('<div class="error">' + privacy_error + '</div>').addClass('error');
      }
    });
  }

  function casagredo_seo_position() {
    $('#seobar_wrapper').appendTo($('#seobar_container'));
  }

  function casagredo_handler_homepage() {
    if ($('html').hasClass('home')) {
      var __home_map_loaded = false;
      var position_map = $('#preview_location').offset().top - $('#preview_location').outerHeight(true);
      $(window).on("scroll", function () {
        var scrollPosition = window.pageYOffset;
        if (!__home_map_loaded && scrollPosition >= position_map) {
          __home_map_loaded = true;
          if (($('#map_canvas').length > 0)) {
            casagredo_initializeMap();
          }
        }
      });
    } else {
      if (($('#map_canvas').length > 0)) {
        casagredo_initializeMap();
      }
    }
  }

  function casagredo_anchor_to_children() {
    if ($(".anchor-item").length > 0) {
      $(".anchor-item").on('click', function () {
        var scrollTopLessHeader = $($.attr(this, 'href')).offset().top - 100;
        $('html, body').animate({
          scrollTop: scrollTopLessHeader
        }, 2000);
      });
    }
  }

  function casagredo_popup() {
    if ($('#popup_wrapper').length > 0) {
      $.fancybox.open({
        src: '#popup_wrapper',
        type: 'inline',
        touch: false,
        autoFocus: false,
        afterLoad: function () {
          lazyLoadInstance.update();
        }
      });
    }
  }


  if (typeof gformInitDatepicker === 'undefined') {
    function gformInitDatepicker() {
      jQuery('.datepicker').each(function () {

        var element = jQuery(this),
            inputId = this.id,
            optionsObj = {
              yearRange: '-100:+20',
              showOn: 'focus',
              dateFormat: 'mm/dd/yy',
              changeMonth: true,
              changeYear: true,
              suppressDatePicker: false,
              onClose: function () {
                element.focus();
                var self = this;
                this.suppressDatePicker = true;
                setTimeout(function () {
                  self.suppressDatePicker = false;
                }, 200);
              },
              beforeShow: function (input, inst) {
                return !this.suppressDatePicker;
              }
            };

        if (element.hasClass('dmy')) {
          optionsObj.dateFormat = 'dd/mm/yy';
        } else if (element.hasClass('dmy_dash')) {
          optionsObj.dateFormat = 'dd-mm-yy';
        } else if (element.hasClass('dmy_dot')) {
          optionsObj.dateFormat = 'dd.mm.yy';
        } else if (element.hasClass('ymd_slash')) {
          optionsObj.dateFormat = 'yy/mm/dd';
        } else if (element.hasClass('ymd_dash')) {
          optionsObj.dateFormat = 'yy-mm-dd';
        } else if (element.hasClass('ymd_dot')) {
          optionsObj.dateFormat = 'yy.mm.dd';
        }

        if (element.hasClass('datepicker_with_icon')) {
          optionsObj.showOn = 'both';
          optionsObj.buttonImage = jQuery('#gforms_calendar_icon_' + inputId).val();
          optionsObj.buttonImageOnly = true;
        }

        inputId = inputId.split('_');

        // allow the user to override the datepicker options object
        optionsObj = gform.applyFilters('gform_datepicker_options_pre_init', optionsObj, inputId[1], inputId[2]);

        element.datepicker(optionsObj);
      });
    }
  }
  if (typeof renderRecaptcha === 'undefined') {
    function renderRecaptcha() {

      jQuery('.ginput_recaptcha').each(function () {

        var $elem = jQuery(this),
            parameters = {
              'sitekey': $elem.data('sitekey'),
              'theme': $elem.data('theme'),
              'tabindex': $elem.data('tabindex')
            };

        if (!$elem.is(':empty')) {
          return;
        }

        if ($elem.data('stoken')) {
          parameters.stoken = $elem.data('stoken');
        }

        var callback = false;

        if ($elem.data('size') == 'invisible') {
          callback = function (token) {
            if (token) {
              $elem.closest('form').submit();
            }
          }
        }

        /**
         * Allows a custom callback function to be executed when the user successfully submits the captcha.
         *
         * @since 2.4.x     The callback will be a function if reCAPTCHA v2 Invisible is used.
         * @since 2.2.5.20
         *
         * @param string|false|object   The name of the callback function or the function object itself to be executed when the user successfully submits the captcha.
         * @param object       $elem    The jQuery object containing the div element with the ginput_recaptcha class for the current reCaptcha field.
         */
        callback = gform.applyFilters('gform_recaptcha_callback', callback, $elem);
        if (callback) {
          parameters.callback = callback;
        }

        $elem.data('widget-id', grecaptcha.render(this.id, parameters));

        if (parameters.tabindex) {
          $elem.find('iframe').attr('tabindex', parameters.tabindex);
        }

        gform.doAction('gform_post_recaptcha_render', $elem);


      });

    }
  }


  function manual_fancybox() {
    $('a[data-manual-fancy]').click(function () {
      var button = $(this);
      var data = button.data('src');
      if (!data) {
        return;
      }
      var html = $(data).text();
      $.fancybox.open(html, {
        'afterShow': function () {
          if (typeof renderRecaptcha !== 'undefined') {
            renderRecaptcha();
          }
          if (typeof gformInitDatepicker !== 'undefined') {
            gformInitDatepicker();
          }

        },
        arrows: false,
        infobar: false
      })
    })
  }

  //2adults for the offers
  function casagredo_resadirect_adults_ovveride(){
    setTimeout(function(){
      window.hhotelResaDirect = function (cname, lg, codeprice, firstroom, codetrack, firstdate) {
        var chunks;
        if (typeof firstdate !== 'undefined') {
          chunks = firstdate.split(';');
          if (chunks.length == 4) {
            chunks[2] = '2';
            firstdate = chunks.join(';');
          }
        }
        hhotelResa(cname, lg, codeprice, firstroom, firstdate, codetrack, "", "", "style=DIRECT");
      };
    },1000);
  }

//Load Window
  $(window).on('load', function () {
    AOS.init({
      duration: 1600,
      disable: function () {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
      }
    });
    refresh_size_queries();
    casagredo_filter_children();
    casagredo_filter_photogallery();
    casagredo_filter_experiences();
  });

//Resize Window
  $(window).resize(function () {
    refresh_size_queries();
    casagredo_responsive_slideshow();
    //videoHeight();
  });
//Dom ready
  $(document).ready(function () {
    window.lazyLoadInstance = new LazyLoad({
      elements_selector: ".lazy",
      threshold: 100
    });
    casagredo_resadirect_adults_ovveride();
    refresh_size_queries();
    casagredo_seo_position();
    casagredo_header_position();
    window.onscroll = function () {
      casagredo_header_position();
    };
    casagredo_open_menu();
    casagredo_open_menu_mobile();
    casagredo_social_login();
    casagredo_languages();
    menuWithBgImages();
    casagredo_slideshow();
    casagredo_responsive_slideshow();
    casagredo_minigallery();
    casagredo_weddings_minigallery();
    page_preview_minigallery();
    suite_children_minigallery();
    children_minigallery();
    children_collapsible_minigallery();
    casagredo_siblings();
    casagredo_dynamic_calendar();

    var plyr_loaded = false;
    $('#play_video').on('click', function () {
      if (!plyr_loaded) {
        var plyr_js_url = 'https://cdn.plyr.io/3.6.2/plyr.polyfilled.js';

        if (typeof plyr_js_url == 'undefined') return;
        $.getScript(plyr_js_url, function () {
          $('#play_video').addClass('loaded');
          videoHomepage();
        });
        plyr_loaded = true;
      }
        videoHomepage();
    });

    //videoHeight();
    casagredo_awards();
    casagredo_collapsibleContent();
    opencloseviewmore();
    web_sdk_offers();
    casagredo_handler_homepage();
    if ($('html').hasClass('home') || $('html').hasClass('page-template-template-location')) {
      $('#itinerarySubmit').on('click', function () {
        casagredo_calcRoute('map_canvas');
      });
    }
    casagredo_social_media();
    // casagredo_form();
    newsletter_validation();
    casagredo_anchor_to_children();
    casagredo_popup();
    manual_fancybox();
    //This is an hack for chrome mobile because the browser doesn't render some D-EDGE custom icons
    if (is_mobile) {
      setTimeout(function () {
        $('.virtual_tour span:first-child').each(function () {
          $(this).css({'font-size': 36});

        });
      }, 3000);
    }

    //Displays if FB Social Connect Plugin is active
    if ($('#guestaccount .desktop-login').length == 1) {
      $('.close-offer-widget').css('opacity', '0.85');
    }

//Slideshow and Video box resizing depending on mobile orientation
    window.addEventListener("orientationchange", function (event) {
      $(window).one('resize', function () {
        var device_custom_height = window.innerHeight - 60;
        if (window.matchMedia("(orientation: landscape)").matches) {
          if ($('.slideshow-container.fullscreen').hasClass('playing_video')) {
            $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', device_custom_height)
          } else {
            $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', '100vh')
          }
        } else {
          if ($('.slideshow-container.fullscreen').hasClass('playing_video')) {
            $('.slideshow-container.fullscreen , #video_box .video_is_mobile.fullscreen').css('height', '400px')
          } else {
            $('.slideshow-container.fullscreen').css('height', '640px');
            $('#video_box .video_is_mobile.fullscreen').css('height', '400px')
          }
        }
      });
    });
  });
})(jQuery);


